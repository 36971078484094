<template>
	<div class="login-container">
		<div id="particles-js"></div>
		<el-form
			ref="loginForm"
			:model="loginForm"
			:rules="loginRules"
			class="login-form bounceInUp"
			auto-complete="on"
			label-position="left"
		>
			<div class="title-container">
				<h3 class="title">
					<!-- {{ $t('login.title') }} -->
					<!--  <div class="system-icon">
            <svg-icon icon-class="logo"/>
					</div>-->
					<div style="margin-bottom:25px">{{ShopName}}</div>
					<div class="title-desc">欢迎登录{{ShopName}}系统</div>
				</h3>
			</div>
			<el-form-item prop="username">
				<span class="svg-container svg-container_login">
					<svg-icon icon-class="user" />
				</span>
				<el-input
					v-model="loginForm.username"
					:placeholder="$t('login.username')"
					name="username"
					type="text"
					auto-complete="on"
				/>
			</el-form-item>
			<el-form-item prop="password">
				<span class="svg-container">
					<svg-icon icon-class="password" />
				</span>
				<el-input
					:type="passwordType"
					v-model="loginForm.password"
					:placeholder="$t('login.password')"
					name="password"
					auto-complete="on"
					@keyup.enter.native="handleLogin"
				/>
				<span class="show-pwd" @click="showPwd">
					<svg-icon icon-class="eye" />
				</span>
			</el-form-item>
			<el-form-item prop="rememberme" style="border: 0px;background: initial;border-radius: 0px;">
				<el-checkbox v-model="loginForm.rememberme">{{$t('login.remember')}}</el-checkbox>
			</el-form-item>
			<el-button
				:loading="loading"
				type="primary"
				style="width:100%;margin-bottom:30px;height:48px;font-size:16px;font-weight:600;"
				@click.native.prevent="handleLogin"
			>{{ $t('login.logIn') }}</el-button>
		</el-form>
	</div>
</template>
<script>
import '@/assets/js/particles.js'
import particles from '@/assets/js/app.js'
import {
	mapGetters
} from 'vuex'
// import { getUserInfo } from '@/api/login'
// import { isvalidUsername } from '@/utils/validate'
export default {
	name: 'login',
	data () {
		const validateUsername = (rule, value, callback) => {
			if (!value.length) {
				callback(new Error('请输入账号'))
			} else {
				callback()
			}
		}
		const validatePassword = (rule, value, callback) => {
			if (!value.length) {
				callback(new Error('请输入密码'))
			} else {
				callback()
			}
		}
		return {
			loginForm: {
				// 17671149645  18666411111  15700064900 15700064911(微商城订单)
				username: '',
				password: '',
				rememberme: false,
			},
			loginRules: {
				username: [{
					required: true,
					trigger: 'blur',
					validator: validateUsername
				}],
				password: [{
					required: true,
					trigger: 'blur',
					validator: validatePassword
				}]
			},
			loading: false,
			showDialog: false,
			redirect: undefined,
			passwordType: 'password',
		}
	},

	computed: {
		...mapGetters([
			'loginState',
			'ShopName',
		])
	},
	watch: {
		$route: {
			handler: function (route) {
				// console.log(route);
				this.redirect = route.query && route.query.redirect
			},
			immediate: true
		}
	},
	methods: {
		showPwd () {
			if (this.passwordType === 'password') {
				this.passwordType = ''
			} else {
				this.passwordType = 'password'
			}
		},
		handleLogin () {

			this.$refs.loginForm.validate(valid => {
				if (valid) {
					this.loading = true
					this.$store.dispatch('Login', this.loginForm).then(() => {
						if (this.loginState) {
							this.loading = false;
							this.$router.push({
								path: '/'
							})
							return;
						} else {
							this.$message.error('用户名或者密码错误');
						}
						this.loading = false;
						// this.$router.push({ path: this.redirect || '/' })
					}).catch((e) => {
						console.log(e);
						this.loading = false;
					})
				} else {
					console.log('error submit!!')
					return false
				}
			})
		},
	},
	created () {

	},
	mounted () {
		particles();
	},
	destroyed () { }
}
</script>
<style lang="less">
#particles-js {
	height: 100%;
	width: 100%;
	position: absolute;
	z-index: -1;
}

@-webkit-keyframes bounceInUp {
	from,
	60%,
	75%,
	90%,
	to {
		-webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}

	from {
		opacity: 0;
		-webkit-transform: translate3d(0, 3000px, 0);
		transform: translate3d(0, 3000px, 0);
	}

	60% {
		opacity: 1;
		-webkit-transform: translate3d(0, -20px, 0);
		transform: translate3d(0, -20px, 0);
	}

	75% {
		-webkit-transform: translate3d(0, 10px, 0);
		transform: translate3d(0, 10px, 0);
	}

	90% {
		-webkit-transform: translate3d(0, -5px, 0);
		transform: translate3d(0, -5px, 0);
	}

	to {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@keyframes bounceInUp {
	from,
	60%,
	75%,
	90%,
	to {
		-webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}

	from {
		opacity: 0;
		-webkit-transform: translate3d(0, 3000px, 0);
		transform: translate3d(0, 3000px, 0);
	}

	60% {
		opacity: 1;
		-webkit-transform: translate3d(0, -20px, 0);
		transform: translate3d(0, -20px, 0);
	}

	75% {
		-webkit-transform: translate3d(0, 10px, 0);
		transform: translate3d(0, 10px, 0);
	}

	90% {
		-webkit-transform: translate3d(0, -5px, 0);
		transform: translate3d(0, -5px, 0);
	}

	to {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

.bounceInUp {
	-webkit-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-webkit-animation-name: bounceInUp;
	animation-name: bounceInUp;
}

.system-icon {
	height: 64px;
	display: flex;
	justify-content: center;
	align-items: center;
	/*background-color: #2d8cf0;*/
	border-radius: 10px;

	.svg-icon {
		width: 156px;
		height: 50px;
		filter: grayscale(200%) brightness(2000%);
		margin-right: 0;
	}
}

.title-desc {
	font-size: 20px;
	font-weight: 500;
	margin-top: 5px;
}

/* 修复input 背景不协调 和光标变色 */
/* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */

@bg: #283443;
@light_gray: #eee;
@cursor: #fff;

@supports (-webkit-mask: none) and (not (cater-color: @cursor)) {
	.login-container .el-input input {
		color: @cursor;

		&::first-line {
			color: @light_gray;
		}
	}
}

/* reset element-ui css */
.login-container {
	.el-input {
		display: inline-block;
		height: 47px;
		width: 85%;

		input {
			background: transparent;
			border: 0px;
			-webkit-appearance: none;
			border-radius: 0px;
			padding: 12px 5px 12px 15px;
			color: @light_gray;
			height: 47px;
			caret-color: @cursor;

			&:-webkit-autofill {
				-webkit-box-shadow: 0 0 0px 1000px @bg inset !important;
				-webkit-text-fill-color: @cursor !important;
			}
		}
	}

	.el-form-item {
		border: 1px solid rgba(255, 255, 255, 0.1);
		background: rgba(0, 0, 0, 0.1);
		border-radius: 5px;
		color: #454545;
	}

	.el-checkbox {
		color: #fff;
	}
}
</style>
<style lang="less" scoped>
@bg: #2d3a4b;
@dark_gray: #889aa4;
@light_gray: #eee;

.login-container {
	position: fixed;
	height: 100%;
	width: 100%;
	background-color: @bg;
	display: flex;
	justify-content: center;
	align-items: center;

	.login-form {
		// position: absolute;
		// left: 0;
		// right: 0;
		width: 440px;
		padding: 35px 35px 15px 35px;
		margin-bottom: 100px;
		// margin: 120px auto;
		// background-color: rgba(255,255,255,.3);
		background-color: #6c7581;
		border-radius: 5px;
	}

	.tips {
		font-size: 14px;
		color: #fff;
		margin-bottom: 10px;

		span {
			&:first-of-type {
				margin-right: 16px;
			}
		}
	}

	.svg-container {
		padding: 6px 5px 6px 15px;
		color: @dark_gray;
		vertical-align: middle;
		width: 30px;
		display: inline-block;

		&_login {
			font-size: 20px;
		}
	}

	.title-container {
		position: relative;

		.title {
			font-size: 26px;
			color: @light_gray;
			margin: 0px auto 40px auto;
			text-align: center;
			font-weight: bold;
		}

		.set-language {
			color: #fff;
			position: absolute;
			top: 5px;
			right: 0px;
		}
	}

	.show-pwd {
		position: absolute;
		right: 10px;
		top: 7px;
		font-size: 16px;
		color: @dark_gray;
		cursor: pointer;
		user-select: none;
	}

	.thirdparty-button {
		position: absolute;
		right: 35px;
		bottom: 28px;
	}
}
</style>
